//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="72"
    height="72"
  >
    <path fill="#C4F0FF" d="M36 0a36 36 0 1 1 0 72 36 36 0 0 1 0-72z" />
    <path
      fill="#6772E5"
      transform="translate(19 15)"
      d="M17 0L0 7.64v11.45C0 29.7 7.25 39.6 17 42c9.75-2.4 17-12.31 17-22.9V7.63L17 0z"
    />
    <path
      fill="#C4F0FF"
      transform="translate(28 28)"
      d="M15 10h-5v5H5v-5H0V5h5V0h5v5h5v5z"
    />
  </svg>
);

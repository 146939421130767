// @flow
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import Page from "../components/Page";
import Section from "../components/Section";
import Shield from "../icons/Shield";
import type { ImageSharp, Insurer, ImageType } from "../types.js";
import { query, MEDIUM_BREAKPOINT } from "../style";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    teamImage: ImageType,
    insurers: { edges: Array<{ node: Insurer }> }
  }
};

export default class Index extends React.Component<Props> {
  render() {
    const {
      mainImage,
      secondImage,
      thirdImage,
      teamImage,
      insurers
    } = this.props.data;
    const num_insurers = insurers.edges.length;
    const Insurers_1 = insurers.edges.slice(0, num_insurers / 2);
    const Insurers_2 = insurers.edges.slice(num_insurers / 2, num_insurers);

    return (
      <Page style={{ background: "#f6f9fc" }} title="Payment & Insurance">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Payment & Insurance"
          subtitle="We do our best to make dealing with insurance and payment as easy as possible so you can focus on your treatment. Our staff is available to answer any questions and help you understand the financials of your medical care."
          cta="Make an appointment"
        />
        <ColumnSection
          // style={{ marginBottom: 210 }}
          color="linear-gradient(90deg,#777bf8,#68F8C4 200%)"
        >
          <Column>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem"
              }}
            >
              <Shield />
              <Title>Accepted Insurance Providers</Title>
            </div>
            <Body
              style={{ maxWidth: 600, textAlign: "center", margin: "auto" }}
            >
              Spine Institute of Idaho can accept a wide variety of insurance
              plans including those listed below. You may need to contact your
              insurance company directly to find out if our providers are
              in-network with your specific plan. Please note our providers are
              in network with the major hospital systems in the valley: St
              Alphonsus Health Alliance and St Luke’s Health Partners.
            </Body>
            <Columns>
              <Body style={{ padding: "0px 30px", flex: "1 0 300px" }}>
                {Insurers_1.map(({ node: { name } }) => (
                  <li key={name}>{name}</li>
                ))}
              </Body>
              <Body style={{ padding: "0px 30px", flex: "1 0 300px" }}>
                {Insurers_2.map(({ node: { name } }) => (
                  <li key={name}>{name}</li>
                ))}
              </Body>
            </Columns>
            <Body
              style={{ maxWidth: 600, textAlign: "center", margin: "auto" }}
            >
              Don’t see your provider listed? We can help with out of network
              requests. Contact our patient coordinator for more information.
              <br />
              <br />
              Payments can be made online, by mail, over the phone or in person.
              Payments are accepted in the form of checks, all major credit or
              debit cards (Visa, Mastercard, Discover, Amex) and cash.
            </Body>
          </Column>
        </ColumnSection>
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

/**<Column>
            <Title>Understanding your bill</Title>
            <Body
              style={{ maxWidth: 500, textAlign: 'center', margin: 'auto' }}
            >
              Medical bills can be confusing, we understand. We are always here
              to answer questions should you have them, but here are the key
              things to understand:
            </Body>
          </Column> */

const Columns = styled.div`
  && {
    display: flex;
    margin: 55px 0px;
    flex-wrap: wrap;
  }
`;

const Column = styled.div``;

const ColumnSection = styled(Section)`
  && {
    ${query(MEDIUM_BREAKPOINT)`
      padding-top: 280px;
    `}
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
`;
// padding: 30px 20px 100px 20px
const Title = styled.h1`
  && {
    color: white;
    text-align: center;
    margin-top: 2rem;
  }
`;

const Body = styled.ul`
  && {
    color: white;
  }
`;

export const pageQuery = graphql`
  query paymentAndInsurance {
    mainImage: file(relativePath: { eq: "square/pt.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/reception.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/meeting.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    teamImage: file(relativePath: { eq: "philosophy/team.jpg" }) {
      childImageSharp {
        resolutions(quality: 70, width: 1040) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    insurers: allContentfulAcceptedInsurance(
      sort: { order: ASC, fields: [name] }
    ) {
      edges {
        node {
          name
        }
      }
    }
  }
`;
